$ges-sustainability: #042f35;
$ges-empowerment: #b6eb3f;
$ges-clean-energy: #1e3881;
$ges-equity: #e4e8da;

$primary: $ges-clean-energy;
$link-color-dark: $ges-empowerment;
$primary-text-emphasis-dark: $ges-empowerment;
$dark: $ges-sustainability;
$light: $ges-equity;

$color-mode-type: 'media-query';

@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons";

html, body {
  height: 100%;
}

.btn-floating-action {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
}

.visible-hover {
  visibility: hidden;
}

:hover > .visible-hover {
  visibility: unset;
}

:hover + .visible-hover {
  visibility: unset;
}

@media (hover: none) {
  .visible-hover {
    visibility: unset;
  }
}

//Hide Arrow From Input Box
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.list-group-item.active {
  z-index: unset;
}

.input-group-text + .form-control::file-selector-button {
  display: none;
}

// Correction: On dark background, the link color should be lighter (i.e. the dark mode link color)
.bg-dark, .list-group-item.active {
  --bs-link-color: var(--bs-link-color-dark);
  --bs-link-hover-color: var(--bs-link-hover-color-dark);
}

// Hide dropdown arrow if the button is only an icon without text
.dropdown-toggle[class*="bi-"]:empty::after {
  display: none;
}

// Avoid margin below pagination
ul.pagination, ol.breadcrumb {
  margin-bottom: 0;
}

.icon-conserve::before {
  display: inline-block;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  width: 0.75em;
  height: 0.75em;
  content: url("/assets/logos/Gemini-Icon.svg");
}
